import React, { useState, useEffect } from "react";
import SportsConfigSection from './SportsConfig';
import {
  TextField,
  Switch,
  Button,
  Card,
  CardContent,
  CardHeader,
  Typography,
  CircularProgress,
  Grid,
  Divider,
  FormControlLabel
} from "@mui/material";

// Update interface to strictly type boolean fields
interface SiteConfigData {
  registerbonus: number;
  affiliatebonus: number;
  comissionpercent: number;
  // Boolean fields
  sports: boolean;
  inr: boolean;
  mvr: boolean;
  aed: boolean;
  pkr: boolean;
  cryptocoin: boolean;
  sbc: boolean;
  casino: boolean;
  lotto: boolean;
  vipclub: boolean;
  clubmembership: boolean;
  bonus: boolean;
  affiliate: boolean;
  crash: boolean;
  originals: boolean;
  livegames: boolean;
  slotsgames: boolean;
  alllivegames: boolean;
  allslotsgames: boolean;
  lotterygames: boolean;
  indiangames: boolean;
  cards: boolean;
  jilli: boolean;
  bdt:boolean;
  clubrake:number;
}






// Type guard to check if a key corresponds to a boolean field
const isBooleanKey = (key: keyof SiteConfigData): boolean => {
  const numericKeys = ['registerbonus', 'affiliatebonus', 'comissionpercent','clubrake'];
  return !numericKeys.includes(key);
};

const SiteConfig = () => {
  const [config, setConfig] = useState<SiteConfigData>({
    registerbonus: 0,
    affiliatebonus: 0,
    comissionpercent: 0,
    sports: false,
    inr: false,
    mvr: false,
    aed: false,
    pkr: false,
    cryptocoin: false,
    sbc: false,
    casino: false,
    lotto: false,
    vipclub: false,
    clubmembership: false,
    bonus: false,
    affiliate: false,
    crash: false,
    originals: false,
    livegames: false,
    slotsgames: false,
    alllivegames: false,
    allslotsgames: false,
    lotterygames: false,
    indiangames: false,
    cards: false,
    jilli: false,
    bdt:false,
    clubrake:0
  });
  const [isLoading, setIsLoading] = useState(true);

  const fetchSiteConfig = async () => {
    try {
      const response = await fetch("https://api.bitrubix.games/siteconfig");
      const data = await response.json();
      setConfig(data);
      setIsLoading(false);
    } catch (error) {
      console.error("Failed to load site configuration:", error);
      setIsLoading(false);
    }
  };

  useEffect(() => {
    fetchSiteConfig();
  }, []);

  // Separate handlers for boolean and number inputs
  const handleBooleanChange = (key: keyof SiteConfigData) => (event: React.ChangeEvent<HTMLInputElement>) => {
    if (isBooleanKey(key)) {
      setConfig(prev => ({
        ...prev,
        [key]: event.target.checked
      }));
    }
  };

  const handleNumberChange = (key: keyof SiteConfigData) => (event: React.ChangeEvent<HTMLInputElement>) => {
    if (!isBooleanKey(key)) {
      setConfig(prev => ({
        ...prev,
        [key]: Number(event.target.value)
      }));
    }
  };

  const handleSaveConfig = async () => {
    try {
      setIsLoading(true);
      const response = await fetch("https://api.bitrubix.games/siteconfig/update", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(config),
      });
      const data = await response.json();

      if (data.updated) {
        alert("Site configuration updated successfully");
        fetchSiteConfig();
      } else {
        alert("No changes detected");
      }
    } catch (error) {
      console.error("Failed to update site configuration:", error);
      alert("Failed to update site configuration");
    } finally {
      setIsLoading(false);
    }
  };

  if (isLoading) {
    return (
      <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
        <CircularProgress />
      </div>
    );
  }

  return (
    <div className="ml-0 lg:ml-[256px] p-2">
      <Card>
        <CardHeader title="Site Configuration" />
        <CardContent>
          <div style={{ display: 'flex', flexDirection: 'column', gap: '1.5rem' }}>
            {/* Numeric Inputs Section */}
            <Grid container spacing={2}>
              <Grid item xs={12} md={4}>
                <TextField
                  label="Register Bonus"
                  type="number"
                  value={config.registerbonus}
                  onChange={handleNumberChange("registerbonus")}
                  fullWidth
                />
              
              </Grid>
              <Grid item xs={12} md={4}>
                <TextField
                  label="Club Rakeback"
                  type="number"
                  value={config.clubrake}
                  onChange={handleNumberChange("clubrake")}
                  fullWidth
                />
              </Grid>
            </Grid>

            <Divider />
            <div>
            <Typography variant="h6" gutterBottom>
                Affiliate Settings
              </Typography>
              <Grid container spacing={2}>
             <Grid item xs={12} md={4}>
                <TextField
                  label="Affiliate Bonus"
                  type="number"
                  value={config.affiliatebonus}
                  onChange={handleNumberChange("affiliatebonus")}
                  fullWidth
                />
              </Grid>
              <Grid item xs={12} md={4}>
                <TextField
                  label="Commission Percentage"
                  type="number"
                  value={config.comissionpercent}
                  onChange={handleNumberChange("comissionpercent")}
                  fullWidth
                />
              </Grid>
            </Grid>
            </div>
            <Divider />
            {/* Currency Settings */}
            <div>
              <Typography variant="h6" gutterBottom>
                Currency Settings
              </Typography>
              <Grid container spacing={2}>
                {[
                  { key: "inr", label: "INR" },
                  { key: "mvr", label: "MVR" },
                  { key: "aed", label: "AED" },
                  { key: "pkr", label: "PKR" },
                  { key: "bdt", label: "BDT" },
                  { key: "cryptocoin", label: "Crypto" },
                  { key: "sbc", label: "SBC" },
                ].map(({ key, label }) => (
                  <Grid item xs={6} md={3} key={key}>
                    <FormControlLabel
                      control={
                        <Switch
                          checked={config[key as keyof SiteConfigData] as boolean}
                          onChange={handleBooleanChange(key as keyof SiteConfigData)}
                        />
                      }
                      label={label}
                    />
                  </Grid>
                ))}
              </Grid>
            </div>

            <Divider />
          <SportsConfigSection />

            <Divider />

            {/* Game Types */}
            <div>
              <Typography variant="h6" gutterBottom>
                Game Types
              </Typography>
              <Grid container spacing={2}>
                {[
                  { key: "sports", label: "Sports" },
                  { key: "casino", label: "Casino" },
                  { key: "lotto", label: "Lotto" },
                  { key: "crash", label: "Crash" },
                  { key: "originals", label: "Originals" },
                  { key: "livegames", label: "Live Games" },
                  { key: "slotsgames", label: "Slots Games" },
                  { key: "alllivegames", label: "All Live Games" },
                  { key: "allslotsgames", label: "All Slots Games" },
                  { key: "lotterygames", label: "Lottery Games" },
                  { key: "indiangames", label: "Indian Games" },
                  { key: "cards", label: "Cards" },
                  { key: "jilli", label: "Jilli" },
                ].map(({ key, label }) => (
                  <Grid item xs={6} md={3} key={key}>
                    <FormControlLabel
                      control={
                        <Switch
                          checked={config[key as keyof SiteConfigData] as boolean}
                          onChange={handleBooleanChange(key as keyof SiteConfigData)}
                        />
                      }
                      label={label}
                    />
                  </Grid>
                ))}
              </Grid>
            </div>

            <Divider />

            {/* Features */}
            <div>
              <Typography variant="h6" gutterBottom>
                Features
              </Typography>
              <Grid container spacing={2}>
                {[
                  { key: "vipclub", label: "VIP Club" },
                  { key: "clubmembership", label: "Club Membership" },
                  { key: "bonus", label: "Bonus" },
                  { key: "affiliate", label: "Affiliate" },
                ].map(({ key, label }) => (
                  <Grid item xs={6} md={3} key={key}>
                    <FormControlLabel
                      control={
                        <Switch
                          checked={config[key as keyof SiteConfigData] as boolean}
                          onChange={handleBooleanChange(key as keyof SiteConfigData)}
                        />
                      }
                      label={label}
                    />
                  </Grid>
                ))}
              </Grid>
            </div>

            <Button
              variant="contained"
              onClick={handleSaveConfig}
              disabled={isLoading}
              fullWidth
              style={{ marginTop: '1.5rem' }}
            >
              {isLoading ? "Saving..." : "Save Configuration"}
            </Button>
          </div>
        </CardContent>
      </Card>
    </div>
  );
};

export default SiteConfig;